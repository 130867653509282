import { reaction, makeAutoObservable } from 'mobx';
import services from '../services';
import { extractErrorMessage } from '../utils/helpers';

class AssessmentsStore {
    loadingData = false;
    loadingAssessmentResults = false;
    _questionAction = false;
    loadingAssessmentId = null;
    startingAssessmentId = null;
    error = null;
    assessments = [];
    results = [];
    assessmentAnswers = {};
    assessment = null;
    // subjects = [];
    statusUpdate = 0;
    reviewingQuestions = [];

    constructor(authStore, companyStore, mainStore, commonStore) {
        makeAutoObservable(this);
        this.authStore = authStore;
        this.companyStore = companyStore;
        this.mainStore = mainStore;
        this.commonStore = commonStore;

        reaction(
            () => this.companyStore.currentCompany,
            (company) => {
                if (company.isAssessmentEnabled) this.loadAssessments();
            }
        );
    }

    logout = () => {
        this.assessments.replace([]);
        this.loadingData = false;
    };

    setError = (error) => {
        this.error = error;
        this.commonStore.error(error);
    };

    async loadAssessment(id, background) {
        if (!background) {
            if (this.loadingAssessmentId === id) return;
            this.loadingAssessmentId = id;
        }
        this.setError(null);
        try {
            let data = await services.Assessments.myAssessment(id, {
                l: this.mainStore.language,
            });
            this.assessment = data;
            //this.subjects.replace(data.subjects);
            this.mainStore.setLayoutTitle(data.name);
        } catch (e) {
            this.setError(
                (e.response && e.response.data && e.response.data.error) ||
                    e.message
            );
        } finally {
            this.loadingAssessmentId = null;
        }
    }

    async loadAssessments() {
        if (!this.companyStore.currentCompany) return null;
        if (this.loadingData) {
            return this.loadingData;
        }
        this.setError(null);

        this.loadingData = (async () => {
            try {
                let data = await services.Assessments.myAssessments(
                    this.contextParams
                );
                this.assessments.replace(data);
                return this.assessments;
            } catch (e) {
                this.setError(
                    (e.response && e.response.data && e.response.data.error) ||
                        e.message
                );
            } finally {
                this.loadingData = null;
            }
        })();
        return this.loadingData;
    }

    async startAssessment(id, targetObj) {
        if (this.startingAssessmentId === id) return;
        this.startingAssessmentId = id;

        this.setError(null);
        try {
            let data = await services.Assessments.startAssessment(id);
            if (data) Object.assign(targetObj || this.assessment, data);
        } catch (e) {
            this.setError(
                (e.response && e.response.data && e.response.data.error) ||
                    e.message
            );
        } finally {
            this.startingAssessmentId = null;
        }
    }

    async completeQuestion(assessmentId, id, questions, assessment) {
        if (this._questionAction) return;
        this._questionAction = true;

        this.setError(null);
        try {
            let data = await services.Assessments.questionActions(
                assessmentId,
                id,
                questions
            );
            if (data) {
                Object.assign(this.assessment, data);
                if (assessment) {
                    // console.log( "update", data )
                    Object.assign(assessment, data);
                }
            }
            return true;
        } catch (e) {
            this.setError(
                (e.response && e.response.data && e.response.data.error) ||
                    e.message
            );
        } finally {
            this._questionAction = false;
        }
    }

    async questionAction(assessmentId, id, action, value) {
        if (this._questionAction) return;
        this._questionAction = true;

        this.setError(null);
        try {
            let data = await services.Assessments.questionAction(
                assessmentId,
                id,
                action,
                value
            );
            if (data) Object.assign(this.assessment, data);
        } catch (e) {
            this.setError(
                (e.response && e.response.data && e.response.data.error) ||
                    e.message
            );
        } finally {
            this._questionAction = false;
        }
    }

    async loadResults(assessmentId) {
        if (this.loadingAssessmentResults) return;
        this.loadingAssessmentResults = true;
        this.results.clear();
        try {
            const data = await services.Assessments.results(assessmentId);
            this.results.replace(data);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingAssessmentResults = false;
        }
    }

    async loadAnswers(companyId, assessmentId) {
        this.setError(null);
        try {
            this.assessmentAnswers = await services.Assessments.answers(
                assessmentId
            );
        } catch (e) {
            this.setError(
                (e.response && e.response.data && e.response.data.error) ||
                    e.message
            );
        } finally {
            this.loadingData = null;
        }
    }

    get contextParams() {
        let params = {};
        if (this.companyStore.currentCompany)
            params.o = this.companyStore.currentCompany.company_id;

        if (this.mainStore.language) params.l = this.mainStore.language;

        return params;
    }
}

export default AssessmentsStore;
