import BaseService from './BaseService';

class InfoSec extends BaseService {
    current() {
        return this.get('/infosec');
    }

    join(email) {
        return this.post('/auth/infosec', { email });
    }
}

export default InfoSec;
