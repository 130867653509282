import { makeAutoObservable } from 'mobx';
import services from '../services';
import { generateCreateEntity } from '../utils/mobx';

class InfosecStore {
    constructor(authStore) {
        makeAutoObservable(this);
        this.authStore = authStore;
    }
    joining = false;
    error = null;

    setError = (error) => {
        this.error = error;
    };

    join = generateCreateEntity('join', this, 'joining', async (email) => {
        this.authStore.logout();
        const token = await services.InfoSec.join(email);
        this.authStore.setToken(token);
    });
}

export default InfosecStore;
