import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useCmi5 } from './hooks/use-cmi5';
import Cmi5 from '@xapi/cmi5';
function App() {
    const cmi5 = useCmi5();
    const [launchParameters, setLaunchParameters] = useState();
    const [launchData, setLaunchData] = useState();
    const [isAuthenticated, setIsAuthenticated] = useState();
    const [XAPI, setXAPI] = useState();

    useEffect(() => {
        setLaunchParameters(cmi5.getLaunchParameters());
    }, []);

    const initCMI5 = () => {
        cmi5.initialize().then(() => {
            setLaunchData(cmi5.getLaunchData());
            setXAPI(Cmi5.xapi);
            setIsAuthenticated(cmi5.isAuthenticated);
        });
    };

    return (
        <div>
            <div>CMI5 test</div>
            <h5>{isAuthenticated ? 'Authenticated' : 'not Authenticated'}</h5>
            <h4>launchParameters</h4>
            {launchParameters && (
                <pre>{JSON.stringify(launchParameters, null, 2)}</pre>
            )}
            <h4>launchData</h4>
            {launchData && <pre>{JSON.stringify(launchData, null, 2)}</pre>}

            <h4>XAPI</h4>
            {XAPI && <pre>{JSON.stringify(XAPI, null, 2)}</pre>}

            {!launchData && <button onClick={initCMI5}>initialize</button>}
        </div>
    );
}

export default observer(App);
