const moment = require('moment');
const momentDurationFormatSetup = require('moment-duration-format');
momentDurationFormatSetup(moment);

const { REACT_APP_STATIC_PREFIX = '/static' } = process.env;

const containsSpecialCharacter = new RegExp(
    /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/
); // eslint-disable-line  no-useless-escape

export function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
}

export function backOffDelay(
    baseDelay,
    counter,
    maxDelay = 240000,
    factor = 2
) {
    const jitter = getRandomArbitrary(0, 0.1);

    let delay = Math.min(baseDelay * Math.exp(counter / factor), maxDelay);
    delay = Math.round(delay * jitter);
    //console.log( "delay:", delay )
    return delay;
}

export function trainingName(name, created) {
    return name || `Training created on ${formatDate(created)}`;
}

export function percentToColor(value) {
    value = value || 0;
    if (value < 20) return 'error';
    if (value > 70) return 'success';
    return 'warning';
}

export function enforceSlash(url) {
    return url.endsWith('/') ? url : url + '/';
}

export function extractErrorMessage(error) {
    if (error === false || error === true || error == null) return;
    let responseData =
        error.response && error.response.data && error.response.data;
    if (
        responseData &&
        responseData.errors &&
        Array.isArray(responseData.errors)
    ) {
        return responseData.errors.map((e) => e.messages.join(';')).join('\n');
    }

    return (responseData && responseData.error) || error.message;
}

export function querystring(name, url = window.location.href) {
    name = name.replace(/[[]]/g, '\\$&');

    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)', 'i');
    const results = regex.exec(url);

    if (!results) {
        return null;
    }
    if (!results[2]) {
        return '';
    }

    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function guid() {
    return (
        this.randomString4() +
        this.randomString4() +
        '-' +
        this.randomString4() +
        '-' +
        this.randomString4() +
        '-' +
        this.randomString4() +
        '-' +
        this.randomString4() +
        this.randomString4() +
        this.randomString4()
    );
}

export function randomString4() {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
}

export function currentDomain() {
    // check if running on server
    if (!(typeof window != 'undefined' && window.document)) {
        // running on server, can use server variables
        return process.env.SERVER_URL;
    } else {
        // we are running on client and can use location
        return (
            window.location.protocol +
            '//' +
            window.location.hostname +
            (window.location.port ? ':' + window.location.port : '')
        );
    }
}

export function validateEmail(email) {
    var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line  no-useless-escape
    return re.test(String(email).toLowerCase());
}

export function isInvalidUserName(userName) {
    return (
        !userName ||
        userName === 'undefined' ||
        containsSpecialCharacter.test(userName)
    );
}

export function formatTime(seconds, round) {
    let duration = moment.duration(seconds, 'seconds');
    if (round) {
        return Math.ceil(duration.asMinutes());
    } else {
        return duration.format();
    }
}

export function subjectProgress(subject) {
    if (subject && subject.type === 3) {
        if (
            subject.questions_count > 0 &&
            subject.status.QUESTIONS_CORRECT > 0
        ) {
            return (
                (subject.status.QUESTIONS_CORRECT / subject.questions_count) *
                100
            );
        } else {
            return 0;
        }
    }
    if (subject && subject.status) {
        if (subject.status.VIDEO_100 > 0) {
            return 100;
        }
        if (subject.status.VIDEO_95 > 0) {
            return 95;
        }
        if (subject.status.VIDEO_75 > 0) {
            return 75;
        }
        if (subject.status.VIDEO_50 > 0) {
            return 50;
        }
        if (subject.status.VIDEO_25 > 0) {
            return 25;
        }
    }
    return 0;
}

/** Example: January 11, 2021 */
export function formatDate(dateString) {
    if (!dateString) {
        return '';
    }
    try {
        return moment(dateString).format('LL');
        //const dt = new Date(dateString);
        //const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        //return dt.getDate() + ' ' + monthNames[dt.getMonth()] + ' ' + dt.getFullYear();
    } catch (e) {
        return '';
    }
}

/** Example: Monday, January 11, 2021  */
export function formatDateWithWeekday(dateString) {
    if (!dateString) {
        return '';
    }
    try {
        return (
            moment(dateString).format('dddd') +
            ', ' +
            moment(dateString).format('LL')
        );
    } catch (e) {
        return '';
    }
}

export function formatDateAndTime(dateString) {
    if (!dateString) {
        return '';
    }
    try {
        return moment(dateString).format('LLL');
    } catch (e) {
        return '';
    }
}

export function formatFrequency(code) {
    switch (code.toUpperCase()) {
        case 'W1':
            return '1 per week';
        case 'W2':
            return '2 per week';
        case 'W3':
            return '3 per week';
        case 'M1':
            return '1 per month';
        case 'M2':
            return '2 per month';
        case 'M3':
            return '3 per month';
        default:
            return code;
    }
}

export function formatWeekday(weekday) {
    switch (weekday) {
        case 1:
            return 'Monday';
        case 2:
            return 'Tuesday';
        case 3:
            return 'Wednesday';
        case 4:
            return 'Thursday';
        case 5:
            return 'Friday';
        case 6:
            return 'Saturday';
        case 7:
            return 'Sunday';
        default:
            return weekday;
    }
}

export function formatTimeOfDay(time) {
    if (time !== '') {
        return time + '.00 GMT';
    }
    return time;
}

export function getWeekday(dateObject) {
    if (!dateObject) {
        return 'null';
    }
    try {
        const weekday = [
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
        ];
        let weekDay = weekday[dateObject.getDay()];
        return weekDay;
    } catch (e) {
        return e.description;
    }
}

export function getHours(dateObject) {
    if (!dateObject) {
        return 'null';
    }
    try {
        return (
            dateObject.getHours() +
            ':' +
            (dateObject.getMinutes() < 10 ? '0' : '') +
            dateObject.getMinutes()
        );
    } catch (e) {
        return e.description;
    }
}

//TODO: use querystring instead
export function buildUrl(url, parameters) {
    let qs = '';
    for (let key in parameters) {
        const value = parameters[key];
        qs += encodeURIComponent(key) + '=' + encodeURIComponent(value) + '&';
    }
    if (qs.length > 0) {
        qs = qs.substring(0, qs.length - 1); //chop off last "&"
        url = url + '?' + qs;
    }
    return url;
}

//TODO: use querystring instead
export function buildXtraParameters(company_id, campaign_id, subject_id) {
    let xtra_params = '';
    if (company_id && company_id > 0) {
        xtra_params += 'o=' + company_id;
    }
    if (campaign_id && campaign_id > 0) {
        if (xtra_params.length > 0) {
            xtra_params += '&';
        }
        xtra_params += 'c=' + campaign_id;
    }
    if (subject_id && subject_id > 0) {
        if (xtra_params.length > 0) {
            xtra_params += '&';
        }
        xtra_params += 's=' + subject_id;
    }
    return xtra_params;
}

export function host() {
    return (
        window.location.protocol +
        '//' +
        window.location.hostname +
        (window.location.port ? ':' + window.location.port : '')
    );
}

export function alert(message) {
    console.info(message);
}

export function t(store, key) {
    const i18n = store ? store.i18n : null;
    if (i18n) {
        return i18n.t(key);
    }
    return key;
}

export function getFlagUrl(key) {
    let flagName;
    switch (key) {
        case 'is':
            flagName = 'icelandic';
            break;
        case 'nn':
            flagName = 'norwegian';
            break;
        case 'de':
            flagName = 'deutch';
            break;
        case 'dk':
            flagName = 'danish';
            break;
        case 'en':
            flagName = 'english';
            break;
        case 'es':
            flagName = 'spanish';
            break;
        case 'fr':
            flagName = 'french';
            break;
        default:
        //Do nothing
    }
    return flagName
        ? `https://resources.awarego.com/localization/flags/${flagName}.svg`
        : '';
}

export function joinPath(...parts) {
    if (!parts) return '';
    let result = '';
    for (let i = 0; i < parts.length; i++) {
        result +=
            parts[i].startsWith('/') && i > 0 ? parts[i].substr(1) : parts[i];

        if (i !== parts.length - 1 && !result.endsWith('/')) result += '/';
    }

    return result;
}

export function resourcePath(path) {
    if (!path) return path;
    if (path.startsWith('http://') || path.startsWith('https://')) return path;
    if (path.startsWith('/static')) {
        return joinPath(REACT_APP_STATIC_PREFIX, path.replace('/static', ''));
    }
    return path;
}

export function getScoreTitle(percentage, brandingStore) {
    let def = brandingStore.getScoreDefinition(percentage);
    if (def) return def.name;

    //fallback support
    if (percentage <= 24) return 'Very risky';
    if (percentage <= 49) return 'Risky';
    if (percentage <= 74) return 'Fair';
    if (percentage <= 89) return 'Very good';
    return 'Excellent';
}

export function calculateColor(percentage, brandingStore) {
    let def = brandingStore.getScoreDefinition(percentage);
    if (def) return def.color;

    //fallback support
    if (percentage <= 24) return '#EB5757';
    if (percentage <= 49) return '#FF8440';
    if (percentage <= 74) return '#F2C94C';
    if (percentage <= 89) return '#8CBD00';
    return '#20A26B';
}
